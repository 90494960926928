import * as d3 from "d3";
import { Clock, Download } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import logo from "./logo.svg"; // Import the SVG file

const API_BASE_URL = "https://server.farviz.xyz/api";

const FarViz = () => {
  const [usernames, setUsernames] = useState(
    "odysseustz, feides, milandereede, omarreid, biohacker, mosnassar, BENNN, camellia, electrafrost, valone, dexhunter, nurtinba, yoddha.eth, smorez, 0htrap1, gautham, haider, vitorangonese, arvinatwild, cdtr, kirina, zahara, shasan, jangle, ph0t0nic, donovansung, fazam, nicovrg, hugofaz, emideluxe, rrominarr, Ysh, dhairyachheda.eth, memester, rudrakanya, calvinlegassick, heyzeus, fisheatschips, beecurious, blackmajic5000, krisx, sambhav, balajis.eth"
  );
  const [networkData, setNetworkData] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [zoomTransform, setZoomTransform] = useState(d3.zoomIdentity);
  const [actionLog, setActionLog] = useState([]);
  const [totalConnections, setTotalConnections] = useState(0);
  const svgRef = useRef(null);
  const simulationRef = useRef(null);
  const nodesRef = useRef(new Map());
  const linksRef = useRef(new Map());
  const clockRef = useRef(null);
  const [completedNetwork, setCompletedNetwork] = useState(null);
  const [processedEvents, setProcessedEvents] = useState(new Set()); //[]
  const zoomRef = useRef(null);
  const actionLogRef = useRef(null); // Ref for Action Log

  const processNetworkData = (data) => {
    if (!data || !data.userData || !data.followData) {
      throw new Error("Invalid data structure received from API");
    }

    const nodes = data.userData.map((user) => ({
      id: user.fid.toString(),
      username: user.username || `User_${user.fid}`,
      displayName: user.displayName || `User ${user.fid}`,
      pfp: user.pfp || "https://placecats.com/g/50/50",
      fid: user.fid,
      followerCount: user.followerCount || 0,
      followingCount: user.followingCount || 0,
      timestamp: new Date(user.timestamp).getTime(),
    }));

    const links = Object.entries(data.followData).flatMap(
      ([sourceFid, follows]) =>
        follows.map((follow) => ({
          source: sourceFid,
          target: follow.targetFid.toString(),
          timestamp: follow.timestamp,
        }))
    );

    const events = [
      ...nodes.map((node) => ({
        type: "user",
        data: node,
        timestamp: node.timestamp,
      })),
      ...links.map((link) => ({
        type: "follow",
        data: link,
        timestamp: link.timestamp,
      })),
    ].sort((a, b) => a.timestamp - b.timestamp);

    const sortedEvents = events.sort((a, b) => a.timestamp - b.timestamp);
    return { nodes, links, events: sortedEvents };
  };

  const handleVisualize = async () => {
    setIsLoading(true);
    setError(null);
    setActionLog([]);
    setTotalConnections(0);
    try {
      const usernameList = usernames.split(",").map((u) => u.trim());

      const response = await fetch(`${API_BASE_URL}/idx-network-data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          usernames: usernameList,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const processedData = processNetworkData(data);
      setNetworkData(processedData);
      const completedNet = computeCompletedNetwork(processedData);
      setCompletedNetwork(completedNet);
      setCurrentTime(
        processedData.events[processedData.events.length - 1].timestamp
      );
    } catch (err) {
      setError(`Failed to fetch network data: ${err.message}`);
      console.error("Error details:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const computeCompletedNetwork = (data) => {
    const nodes = new Map(data.nodes.map((node) => [node.id, node]));
    const links = data.links.map((link) => ({
      source: nodes.get(link.source),
      target: nodes.get(link.target),
    }));
    return { nodes: Array.from(nodes.values()), links };
  };

  const preProcessEvents = (events) => {
    const processedEvents = [];
    const nodes = new Map();
    const links = new Map();

    events.forEach((event) => {
      if (event.type === "user") {
        if (!nodes.has(event.data.id)) {
          nodes.set(event.data.id, event.data);
          processedEvents.push({
            ...event,
            action: "addNode",
            node: event.data,
          });
        }
      } else if (event.type === "follow") {
        const sourceNode = nodes.get(event.data.source);
        const targetNode = nodes.get(event.data.target);
        if (sourceNode && targetNode) {
          const linkKey = `${event.data.source}-${event.data.target}`;
          if (!links.has(linkKey)) {
            links.set(linkKey, { source: sourceNode, target: targetNode });
            processedEvents.push({
              ...event,
              action: "addLink",
              link: { source: sourceNode, target: targetNode },
            });
          }
        }
      }
    });

    return processedEvents;
  };

  useEffect(() => {
    if (networkData) {
      const completedNet = computeCompletedNetwork(networkData);
      setCompletedNetwork(completedNet);
      setProcessedEvents(preProcessEvents(networkData.events));
      setCurrentTime(
        networkData.events[networkData.events.length - 1].timestamp
      );
    }
  }, [networkData]);

  useEffect(() => {
    if (completedNetwork && svgRef.current) {
      initializeVisualization();
      // Show the completed graph initially
      updateNetwork(completedNetwork.nodes, completedNetwork.links);
    }
  }, [completedNetwork]);

  const initializeVisualization = () => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const width = 800;
    const height = 600;
    const margin = { top: 50, right: 50, bottom: 50, left: 50 };

    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Define arrow markers
    svg
      .append("defs")
      .selectAll("marker")
      .data(["end"])
      .enter()
      .append("marker")
      .attr("id", String)
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 15)
      .attr("refY", 0)
      .attr("markerWidth", 6)
      .attr("markerHeight", 6)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M0,-5L10,0L0,5")
      .attr("fill", "#999");

    const simulation = d3
      .forceSimulation()
      .force(
        "link",
        d3
          .forceLink()
          .id((d) => d.id)
          .distance(100) // Reduced from 150 to accommodate more nodes
      )
      .force("charge", d3.forceManyBody().strength(-300)) // Reduced from -500 to allow closer packing
      .force("center", d3.forceCenter(innerWidth / 2, innerHeight / 2))
      .force("collide", d3.forceCollide().radius(25)) // Reduced from 60 to allow closer packing
      .force("boundary", () => {
        for (let node of simulation.nodes()) {
          node.x = Math.max(40, Math.min(innerWidth - 40, node.x));
          node.y = Math.max(40, Math.min(innerHeight - 40, node.y));
        }
      });

    simulationRef.current = simulation;

    g.append("g").attr("class", "links");
    g.append("g").attr("class", "nodes");

    simulation.on("tick", () => {
      g.select(".links")
        .selectAll("path")
        .attr("d", (d) => {
          const dx = d.target.x - d.source.x,
            dy = d.target.y - d.source.y,
            dr = Math.sqrt(dx * dx + dy * dy);
          return `M${d.source.x},${d.source.y}A${dr},${dr} 0 0,1 ${d.target.x},${d.target.y}`;
        });

      g.select(".nodes")
        .selectAll("g")
        .attr("transform", (d) => `translate(${d.x},${d.y})`);
    });

    const zoom = d3
      .zoom()
      .scaleExtent([0.1, 4])
      .on("zoom", (event) => {
        if (!isPlaying) {
          setZoomTransform(event.transform);
          g.attr("transform", event.transform);
        }
      });

    svg.call(zoom);
    zoomRef.current = zoom; // Store zoom behavior in a ref

    // Initialize with empty data
    updateNetwork([], []);
  };

  const updateNetwork = (nodes, links) => {
    const svg = d3.select(svgRef.current);
    const g = svg.select("g");
    const simulation = simulationRef.current;
    const zoom = zoomRef.current; // Get zoom behavior from ref

    // Calculate node size based on the number of nodes
    const nodeSize = Math.max(10, Math.min(20, 30 - nodes.length / 10));
    const imageX = -nodeSize * 0.8;
    const imageY = -nodeSize * 0.8;
    const imageWidth = nodeSize * 1.6;
    const imageHeight = nodeSize * 1.6;
    const clipPathRadius = nodeSize * 0.8;
    // Update nodes
    const node = g
      .select(".nodes")
      .selectAll("g")
      .data(nodes, (d) => d.id)
      .join(
        (enter) => {
          const nodeGroup = enter.append("g").call(drag(simulation));

          nodeGroup
            .append("circle")
            .attr("r", nodeSize)
            .attr("fill", "transparent")
            .attr("stroke", "#fff")
            .attr("stroke-width", 2);

          nodeGroup
            .append("image")
            .attr("xlink:href", (d) => d.pfp)
            .attr("x", -nodeSize * 0.8)
            .attr("y", -nodeSize * 0.8)
            .attr("width", nodeSize * 1.6)
            .attr("height", nodeSize * 1.6)
            .attr("clip-path", `circle(${nodeSize * 0.8}px at center)`);

          // Add hover effects
          const tooltip = d3
            .select("body")
            .append("div")
            .attr("class", "tooltip")
            .style("opacity", 0)
            .style("position", "absolute")
            .style("text-align", "center")
            .style("padding", "8px")
            .style("font", "12px sans-serif")
            .style("background", "lightsteelblue")
            .style("border", "0px")
            .style("border-radius", "8px")
            .style("pointer-events", "none");

          nodeGroup
            .on("mouseover", function (event, d) {
              const scaleFactor = 1.8; // Adjust the scale factor as desired

              const deltaWidth = imageWidth * (scaleFactor - 1);
              const deltaHeight = imageHeight * (scaleFactor - 1);

              const newX = imageX - deltaWidth / 2;
              const newY = imageY - deltaHeight / 2;

              d3.select(this)
                .select("image")
                .transition()
                .duration(300)
                .attr("x", newX)
                .attr("y", newY)
                .attr("width", imageWidth * scaleFactor)
                .attr("height", imageHeight * scaleFactor)
                .attr(
                  "clip-path",
                  `circle(${clipPathRadius * scaleFactor}px at center)`
                );

              tooltip.transition().duration(200).style("opacity", 0.9);
              tooltip
                .html(
                  `
                    <strong>${d.displayName}</strong><br/>
                    @${d.username}<br/>
                    FID: ${d.fid}<br/>
                  `
                )
                .style("left", event.pageX + 10 + "px")
                .style("top", event.pageY - 28 + "px");
            })
            .on("mouseout", function () {
              d3.select(this)
                .select("image")
                .transition()
                .duration(300)
                .attr("x", imageX)
                .attr("y", imageY)
                .attr("width", imageWidth)
                .attr("height", imageHeight)
                .attr("clip-path", `circle(${clipPathRadius}px at center)`);

              // Hide the tooltip
              tooltip.transition().duration(500).style("opacity", 0);
            });

          return nodeGroup;
        },
        (update) => update,
        (exit) => exit.remove()
      );

    node.on("click", (event, d) => {
      window.open(`https://warpcast.com/${d.username}`, "_blank");
    });

    // Filter links where both source and target nodes exist
    const validLinks = links.filter(
      (link) => link.source && link.target && link.source.id && link.target.id
    );

    // Update links
    const link = g
      .select(".links")
      .selectAll("path")
      .data(validLinks, (d) => `${d.source.id}-${d.target.id}`)
      .join(
        (enter) =>
          enter
            .append("path")
            .attr("fill", "none")
            .attr("stroke", "#999")
            .attr("stroke-opacity", 0.6)
            .attr("stroke-width", 1.5) // Reduced from 2 for less visual clutter
            .attr("marker-end", "url(#end)"),
        (update) => update,
        (exit) => exit.remove()
      );

    // Update simulation
    simulation.nodes(nodes);
    simulation.force("link").links(validLinks);

    simulation.alpha(1).restart();

    // Store current nodes and links for future updates
    nodesRef.current = new Map(nodes.map((d) => [d.id, d]));
    linksRef.current = new Map(
      validLinks.map((d) => [`${d.source.id}-${d.target.id}`, d])
    );

    setTotalConnections(validLinks.length);
    // Adjust zoom based on the number of nodes
    // const zoomLevel = Math.max(0.5, Math.min(2, 10 / Math.sqrt(nodes.length)));
    // const svg_element = svg.node();
    // const svgWidth =
    //   svg_element.clientWidth || svg_element.parentNode.clientWidth;
    // const svgHeight =
    //   svg_element.clientHeight || svg_element.parentNode.clientHeight;
    // const transform = d3.zoomIdentity
    //   .translate(svgWidth / 2, svgHeight / 2)
    //   .scale(zoomLevel)
    //   .translate(-svgWidth / 2, -svgHeight / 2);

    // svg.transition().duration(750).call(zoom.transform, transform);
  };

  const updateNetworkForTime = (time) => {
    if (!processedEvents.length) return;

    const visibleEvents = processedEvents.filter(
      (event) => event.timestamp <= time
    );
    const nodes = new Map();
    const links = new Map();
    const newActions = [];

    visibleEvents.forEach((event) => {
      if (event.action === "addNode") {
        nodes.set(event.node.id, event.node);
        newActions.push(`User created: ${event.node.username}`);
      } else if (event.action === "addLink") {
        const linkKey = `${event.link.source.id}-${event.link.target.id}`;
        if (!links.has(linkKey)) {
          links.set(linkKey, event.link);
          newActions.push(
            `${event.link.source.username} follows ${event.link.target.username}`
          );
        }
      }
    });

    updateNetwork(Array.from(nodes.values()), Array.from(links.values()));

    // Only update action log if there are new actions
    if (newActions.length > 0) {
      setActionLog(newActions);
    }
  };

  const drag = (simulation) => {
    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    return d3
      .drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  };

  const playAnimation = () => {
    const svg = d3.select(svgRef.current);
    const zoom = zoomRef.current;

    setIsPlaying(true);
    setActionLog([]);

    // Disable zoom and pan during animation
    svg.on(".zoom", null);

    const startTime = processedEvents[0].timestamp;
    const endTime = processedEvents[processedEvents.length - 1].timestamp;
    const duration = 30000; // 30 seconds for the entire animation

    // Start with an empty network
    updateNetwork([], []);

    d3.select(svgRef.current)
      .transition()
      .duration(duration)
      .tween("time", () => {
        return (t) => {
          const currentTime = startTime + (endTime - startTime) * t;
          setCurrentTime(currentTime);
          updateNetworkForTime(currentTime);

          if (clockRef.current) {
            const rotation = t * 360;
            clockRef.current.style.transform = `rotate(${rotation}deg)`;
          }
        };
      })
      .on("end", () => {
        setIsPlaying(false);
        // Re-enable zoom and pan after animation
        if (zoom) {
          svg.call(zoom);
        }
        // Ensure the final state is the completed network
        updateNetwork(completedNetwork.nodes, completedNetwork.links);
      });
  };

  const downloadAdjacencyMatrix = () => {
    if (!networkData) return;

    const matrix = {};
    networkData.nodes.forEach((node) => {
      matrix[node.id] = {};
      networkData.nodes.forEach((otherNode) => {
        matrix[node.id][otherNode.id] = 0;
      });
    });

    networkData.links.forEach((link) => {
      const sourceId =
        typeof link.source === "object" ? link.source.id : link.source;
      const targetId =
        typeof link.target === "object" ? link.target.id : link.target;

      if (matrix[sourceId] && matrix[sourceId][targetId] !== undefined) {
        matrix[sourceId][targetId] = 1;
      } else {
        console.warn(
          `Undefined matrix entry for source: ${sourceId}, target: ${targetId}`
        );
      }
    });

    const csv = ["Source,Target,Value"];
    Object.entries(matrix).forEach(([source, targets]) => {
      Object.entries(targets).forEach(([target, value]) => {
        csv.push(`${source},${target},${value}`);
      });
    });

    const blob = new Blob([csv.join("\n")], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "farcaster_adjacency_matrix.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  // Scroll to the bottom of the Action Log whenever it updates
  useEffect(() => {
    if (actionLogRef.current) {
      actionLogRef.current.scrollTop = actionLogRef.current.scrollHeight;
    }
  }, [actionLog]);

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors duration-200 flex flex-col">
      <header className="bg-gray-100 dark:bg-gray-800 shadow">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <img src={logo} alt="FarViz Logo" className="h-10 w-10 mr-3" />
            <h1 className="text-2xl font-bold text-gray-800 dark:text-white">
              FarViz - Farcaster Network Visualizer
            </h1>
          </div>
        </div>
      </header>

      <div className="container mx-auto px-4 py-8 flex flex-col flex-grow">
        <div className="mb-6">
          <textarea
            className="w-full p-3 border rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white border-gray-300 dark:border-gray-700 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent"
            value={usernames}
            onChange={(e) => setUsernames(e.target.value)}
            placeholder="Enter Farcaster usernames separated by commas (e.g., user1,user2,user3)"
            rows={4}
          />
        </div>
        <div className="mb-6 flex space-x-4">
          <button
            className="bg-blue-500 text-white px-6 py-3 rounded-lg flex items-center justify-center hover:bg-blue-600 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleVisualize}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Loading...
              </>
            ) : (
              "Visualize Network"
            )}
          </button>
          <button
            className="bg-yellow-500 text-white px-6 py-3 rounded-lg hover:bg-yellow-600 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
            onClick={playAnimation}
            disabled={!networkData || isPlaying}
          >
            {isPlaying ? (
              <>
                <Clock ref={clockRef} className="animate-spin mr-2 h-5 w-5" />
                Playing...
              </>
            ) : (
              "Play Animation"
            )}
          </button>
          <button
            className="bg-green-500 text-white px-6 py-3 rounded-lg hover:bg-green-600 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
            onClick={downloadAdjacencyMatrix}
            disabled={!networkData}
          >
            <Download className="mr-2 h-5 w-5" />
            Download Adjacency Matrix
          </button>
        </div>
        {error && <div className="text-red-500 mt-2 mb-4">{error}</div>}
        {networkData && (
          <div className="mt-6 mb-8">
            <div className="flex items-center justify-between mb-4">
              <div className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                Current Time:
              </div>
              <div className="text-3xl font-mono bg-gray-100 dark:bg-gray-800 text-blue-600 dark:text-blue-400 px-4 py-2 rounded-lg shadow">
                {formatTime(currentTime || networkData.events[0].timestamp)}
              </div>
            </div>
            <input
              type="range"
              min={networkData.events[0].timestamp}
              max={networkData.events[networkData.events.length - 1].timestamp}
              value={currentTime || networkData.events[0].timestamp}
              onChange={(e) => {
                const newTime = Number(e.target.value);
                setCurrentTime(newTime);
                updateNetworkForTime(newTime);
              }}
              className="w-full"
            />
            <div className="flex justify-between mt-2 text-sm text-gray-600 dark:text-gray-400">
              <span>{formatTime(networkData.events[0].timestamp)}</span>
              <span>
                {formatTime(
                  networkData.events[networkData.events.length - 1].timestamp
                )}
              </span>
            </div>
          </div>
        )}
        <div className="flex space-x-4" style={{ height: "600px" }}>
          <div className="w-2/3">
            {isLoading && !networkData ? (
              <div className="flex justify-center items-center h-full">
                <svg
                  className="animate-spin h-16 w-16 text-blue-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            ) : (
              <div className="border border-gray-300 dark:border-gray-700 rounded-lg overflow-hidden h-full">
                <svg
                  ref={svgRef}
                  width={800}
                  height={600}
                  className="w-full h-full bg-white dark:bg-gray-800"
                />
              </div>
            )}
          </div>
          <div className="w-1/3 flex flex-col">
            <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow mb-4">
              <h2 className="text-xl font-bold mb-4 text-gray-900 dark:text-white">
                Network Stats
              </h2>
              <p className="text-gray-700 dark:text-gray-300">
                Total Connections: {totalConnections}
              </p>
            </div>
            <div
              ref={actionLogRef}
              className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow flex-1 overflow-y-auto"
            >
              <h2 className="text-xl font-bold mb-4 text-gray-900 dark:text-white">
                Action Log
              </h2>
              <ul className="space-y-2">
                {actionLog.map((action, index) => (
                  <li key={index} className="text-gray-700 dark:text-gray-300">
                    {action}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FarViz;
